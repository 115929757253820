import { default as appointments_45doctorsJjNDe3042IMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/appointments-doctors.vue?macro=true";
import { default as appointmentsW0W5ORUAHOMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/appointments.vue?macro=true";
import { default as doctorsOa3BsQyiJuMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/doctors.vue?macro=true";
import { default as indexHJqlMSsqvEMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/index.vue?macro=true";
import { default as initial_45recordsY5sxuhcbyEMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/initial-records.vue?macro=true";
import { default as indexckpfDlcgd0Meta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/[id]/index.vue?macro=true";
import { default as toothUD1AbqnG3NMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/[id]/tooth.vue?macro=true";
import { default as indexZf6hBfW2mIMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/index.vue?macro=true";
import { default as profileXDGjpwLkRbMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/profile.vue?macro=true";
import { default as report68vCwwAG4jMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/report.vue?macro=true";
import { default as reportseC9LVFS2WCMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/reports.vue?macro=true";
import { default as reviewsXTMaYjJt1YMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/reviews.vue?macro=true";
import { default as salariesgyg2SeBdY4Meta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/salaries.vue?macro=true";
import { default as services6BWWPYP1wbMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/services.vue?macro=true";
import { default as walletJ5Edfet2FZMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/wallet.vue?macro=true";
import { default as indexqkJHTwqnN2Meta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/index/index.vue?macro=true";
import { default as serviceso6cbNsoChRMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/index/services.vue?macro=true";
import { default as login2rL3HdoVPtMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/pages/login.vue?macro=true";
import { default as component_45stubeZIb1zlWkyMeta } from "/home/dr-ikramov/dental-implantology-front-nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubeZIb1zlWky } from "/home/dr-ikramov/dental-implantology-front-nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-appointments-doctors___uz",
    path: "/uz/admin/appointments-doctors",
    meta: appointments_45doctorsJjNDe3042IMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/appointments-doctors.vue").then(m => m.default || m)
  },
  {
    name: "admin-appointments-doctors___ru",
    path: "/admin/appointments-doctors",
    meta: appointments_45doctorsJjNDe3042IMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/appointments-doctors.vue").then(m => m.default || m)
  },
  {
    name: "admin-appointments-doctors___en",
    path: "/en/admin/appointments-doctors",
    meta: appointments_45doctorsJjNDe3042IMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/appointments-doctors.vue").then(m => m.default || m)
  },
  {
    name: "admin-appointments___uz",
    path: "/uz/admin/appointments",
    meta: appointmentsW0W5ORUAHOMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/appointments.vue").then(m => m.default || m)
  },
  {
    name: "admin-appointments___ru",
    path: "/admin/appointments",
    meta: appointmentsW0W5ORUAHOMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/appointments.vue").then(m => m.default || m)
  },
  {
    name: "admin-appointments___en",
    path: "/en/admin/appointments",
    meta: appointmentsW0W5ORUAHOMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/appointments.vue").then(m => m.default || m)
  },
  {
    name: "admin-doctors___uz",
    path: "/uz/admin/doctors",
    meta: doctorsOa3BsQyiJuMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/doctors.vue").then(m => m.default || m)
  },
  {
    name: "admin-doctors___ru",
    path: "/admin/doctors",
    meta: doctorsOa3BsQyiJuMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/doctors.vue").then(m => m.default || m)
  },
  {
    name: "admin-doctors___en",
    path: "/en/admin/doctors",
    meta: doctorsOa3BsQyiJuMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/doctors.vue").then(m => m.default || m)
  },
  {
    name: "admin___uz",
    path: "/uz/admin",
    meta: indexHJqlMSsqvEMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___ru",
    path: "/admin",
    meta: indexHJqlMSsqvEMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexHJqlMSsqvEMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-initial-records___uz",
    path: "/uz/admin/initial-records",
    meta: initial_45recordsY5sxuhcbyEMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/initial-records.vue").then(m => m.default || m)
  },
  {
    name: "admin-initial-records___ru",
    path: "/admin/initial-records",
    meta: initial_45recordsY5sxuhcbyEMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/initial-records.vue").then(m => m.default || m)
  },
  {
    name: "admin-initial-records___en",
    path: "/en/admin/initial-records",
    meta: initial_45recordsY5sxuhcbyEMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/initial-records.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients-id___uz",
    path: "/uz/admin/patients/:id()",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients-id___ru",
    path: "/admin/patients/:id()",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients-id___en",
    path: "/en/admin/patients/:id()",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients-id-tooth___uz",
    path: "/uz/admin/patients/:id()/tooth",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/[id]/tooth.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients-id-tooth___ru",
    path: "/admin/patients/:id()/tooth",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/[id]/tooth.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients-id-tooth___en",
    path: "/en/admin/patients/:id()/tooth",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/[id]/tooth.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients___uz",
    path: "/uz/admin/patients",
    meta: indexZf6hBfW2mIMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients___ru",
    path: "/admin/patients",
    meta: indexZf6hBfW2mIMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-patients___en",
    path: "/en/admin/patients",
    meta: indexZf6hBfW2mIMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/patients/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-profile___uz",
    path: "/uz/admin/profile",
    meta: profileXDGjpwLkRbMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/profile.vue").then(m => m.default || m)
  },
  {
    name: "admin-profile___ru",
    path: "/admin/profile",
    meta: profileXDGjpwLkRbMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/profile.vue").then(m => m.default || m)
  },
  {
    name: "admin-profile___en",
    path: "/en/admin/profile",
    meta: profileXDGjpwLkRbMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/profile.vue").then(m => m.default || m)
  },
  {
    name: "admin-report___uz",
    path: "/uz/admin/report",
    meta: report68vCwwAG4jMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/report.vue").then(m => m.default || m)
  },
  {
    name: "admin-report___ru",
    path: "/admin/report",
    meta: report68vCwwAG4jMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/report.vue").then(m => m.default || m)
  },
  {
    name: "admin-report___en",
    path: "/en/admin/report",
    meta: report68vCwwAG4jMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/report.vue").then(m => m.default || m)
  },
  {
    name: "admin-reports___uz",
    path: "/uz/admin/reports",
    meta: reportseC9LVFS2WCMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    name: "admin-reports___ru",
    path: "/admin/reports",
    meta: reportseC9LVFS2WCMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    name: "admin-reports___en",
    path: "/en/admin/reports",
    meta: reportseC9LVFS2WCMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    name: "admin-reviews___uz",
    path: "/uz/admin/reviews",
    meta: reviewsXTMaYjJt1YMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/reviews.vue").then(m => m.default || m)
  },
  {
    name: "admin-reviews___ru",
    path: "/admin/reviews",
    meta: reviewsXTMaYjJt1YMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/reviews.vue").then(m => m.default || m)
  },
  {
    name: "admin-reviews___en",
    path: "/en/admin/reviews",
    meta: reviewsXTMaYjJt1YMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/reviews.vue").then(m => m.default || m)
  },
  {
    name: "admin-salaries___uz",
    path: "/uz/admin/salaries",
    meta: salariesgyg2SeBdY4Meta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/salaries.vue").then(m => m.default || m)
  },
  {
    name: "admin-salaries___ru",
    path: "/admin/salaries",
    meta: salariesgyg2SeBdY4Meta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/salaries.vue").then(m => m.default || m)
  },
  {
    name: "admin-salaries___en",
    path: "/en/admin/salaries",
    meta: salariesgyg2SeBdY4Meta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/salaries.vue").then(m => m.default || m)
  },
  {
    name: "admin-services___uz",
    path: "/uz/admin/services",
    meta: services6BWWPYP1wbMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/services.vue").then(m => m.default || m)
  },
  {
    name: "admin-services___ru",
    path: "/admin/services",
    meta: services6BWWPYP1wbMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/services.vue").then(m => m.default || m)
  },
  {
    name: "admin-services___en",
    path: "/en/admin/services",
    meta: services6BWWPYP1wbMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/services.vue").then(m => m.default || m)
  },
  {
    name: "admin-wallet___uz",
    path: "/uz/admin/wallet",
    meta: walletJ5Edfet2FZMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/wallet.vue").then(m => m.default || m)
  },
  {
    name: "admin-wallet___ru",
    path: "/admin/wallet",
    meta: walletJ5Edfet2FZMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/wallet.vue").then(m => m.default || m)
  },
  {
    name: "admin-wallet___en",
    path: "/en/admin/wallet",
    meta: walletJ5Edfet2FZMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/admin/wallet.vue").then(m => m.default || m)
  },
  {
    name: "index___uz",
    path: "/uz",
    meta: indexqkJHTwqnN2Meta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/",
    meta: indexqkJHTwqnN2Meta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexqkJHTwqnN2Meta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "index-services___uz",
    path: "/uz//services",
    meta: serviceso6cbNsoChRMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/index/services.vue").then(m => m.default || m)
  },
  {
    name: "index-services___ru",
    path: "//services",
    meta: serviceso6cbNsoChRMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/index/services.vue").then(m => m.default || m)
  },
  {
    name: "index-services___en",
    path: "/en//services",
    meta: serviceso6cbNsoChRMeta || {},
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/index/services.vue").then(m => m.default || m)
  },
  {
    name: "login___uz",
    path: "/uz/login",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___ru",
    path: "/login",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/home/dr-ikramov/dental-implantology-front-nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: component_45stubeZIb1zlWkyMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubeZIb1zlWky
  },
  {
    name: component_45stubeZIb1zlWkyMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubeZIb1zlWky
  },
  {
    name: component_45stubeZIb1zlWkyMeta?.name,
    path: "/uz-sitemap.xml",
    component: component_45stubeZIb1zlWky
  },
  {
    name: component_45stubeZIb1zlWkyMeta?.name,
    path: "/ru-sitemap.xml",
    component: component_45stubeZIb1zlWky
  },
  {
    name: component_45stubeZIb1zlWkyMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubeZIb1zlWky
  }
]